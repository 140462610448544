// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import "./top.css"

const Top = () => (
  <div className="top__outer">
    <div className={"top__wrapper"}>
      <h1 className="top__hero-heading">
        Automatiseret styring af dit vedligehold
      </h1>
      <p className="top__hero-subtitle">
        Få det fulde overblik over dit vedligehold med OnLion - et cloud baseret
        system som holder styr på dit udstyr, og sender opgaver direkte til dem
        som skal udføre dem.
      </p>
      <label className="label">Prøv det gratis nu</label>
    </div>
  </div>
)

export default Top
